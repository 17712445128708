(function($) {
	require('simpleCart.min.js');

	simpleCart({
    checkout: {
			type: 'PayPal',
			email: 'digital@cox.net',
			// use paypal sandbox, default is false
			sandbox: true,
			// url to return to on successful checkout, default is null
			success: 'thanks.html',
			// url to return to on cancelled checkout, default is null
			cancel: 'cancel.html',
		},
		cartColumns: [
			{
				view: function(item, col) {
					return '<a class="simpleCart_decrement badge glyphicon glyphicon-minus" href="javascript:;"><span class="sr-only">Subtract One</span></span>';
				},
				attr: 'decrement',
			},
			{ attr: 'quantity', label: 'Qty' },
			{
				view: function(item, col) {
					return '<a class="simpleCart_increment badge glyphicon glyphicon-plus" href="javascript:;"><span class="sr-only">Add One</span></span>';
				},
				attr: 'increment',
			},
			{ attr: 'name', label: 'Product' },
			{ attr: 'price', label: 'Price', view: 'currency' },
			{
				view: function(item, col) {
					return '<img class="item-image" src="' + item.get('image') + '">';
				},
				attr: 'image',
			},
		],
	});

	function toggleCart() {
		$('body').toggleClass('has-cart', simpleCart.quantity() > 0);
	}

	simpleCart.bind('beforeSave', toggleCart);
	simpleCart.bind('load', toggleCart);
})(jQuery);
